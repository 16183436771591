import * as React from "react";
import {Employee} from "./Employee";
import {EmployeeList} from "./EmployeeList";
import {EmployeeModal} from "./EmployeeModal";
import {Event} from "./Event";
import * as InfiniteScroll from 'react-infinite-scroller';

export interface EmployeeViewProps {
}

export interface EmployeeViewState {
    employees: ReadonlyArray<Employee>,
    regions: ReadonlyArray<string>,
    regionFilter?: string,
    onTradeFilter?: boolean,
    offTradeFilter?: boolean,
    dateLatestDutyFilter?: string,

    daysLatestDuty: number,
    dateLatestDuty: Date,

    selectedEmployee?: Employee,
    employeeModalOpen: boolean,
    dutiesMap: { [key: number]: ReadonlyArray<Event> },

    moreToLoad: boolean,
    infiniteLoaderKey: number,

    filterHasChanged: boolean,
}

export class EmployeeView extends React.Component<EmployeeViewProps, EmployeeViewState> {
    state: Readonly<EmployeeViewState> = {
        employees: [],
        regions: [],
        regionFilter: undefined,
        onTradeFilter: undefined,
        offTradeFilter: undefined,
        dateLatestDutyFilter: undefined,
        daysLatestDuty: 14,
        dateLatestDuty: new Date(),
        selectedEmployee: undefined,
        employeeModalOpen: false,
        dutiesMap: {},
        moreToLoad: true,
        infiniteLoaderKey: 0,
        filterHasChanged: false,
    };

    async getEmployees(page: number, region?: string, onTrade?: boolean, offTrade?: boolean, dateLatestDuty?: string): Promise<{ employees: ReadonlyArray<Employee>, regions: ReadonlyArray<string>, moreToLoad: boolean }> {
        const response = await fetch('/api/employees/get', {
            method: 'POST',
            body: JSON.stringify({
                page,
                region,
                onTrade,
                offTrade,
                dateLatestDuty
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        } as RequestInit);

        return await response.json();
    }

    private loadEmployees(page: number = 0, reset: boolean = false) {
        this.getEmployees(page, this.state.regionFilter, this.state.onTradeFilter, this.state.offTradeFilter, this.state.dateLatestDutyFilter).then((response) => {
            const infiniteLoaderKey = this.state.infiniteLoaderKey + (reset ? 1 : 0);

            this.setState({
                employees: reset ? response.employees : this.state.employees.concat(response.employees),
                regions: response.regions,
                moreToLoad: response.moreToLoad,
                infiniteLoaderKey: infiniteLoaderKey,
                filterHasChanged: false,
            })
        });
    }

    private async getLatestDuties(employeeId: number): Promise<{ events: ReadonlyArray<Event> }> {
        const response = await fetch('/api/employees/getLatestDuties', {
            method: 'POST',
            body: JSON.stringify({
                employeeId: employeeId,
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        } as RequestInit);

        return await response.json();
    }

    regionSelectChange(event: React.FormEvent<HTMLSelectElement>) {
        const value = event.currentTarget.value == "null" ? undefined : event.currentTarget.value;

        this.setState({
            regionFilter: value,
            filterHasChanged: this.state.filterHasChanged || this.state.regionFilter != value,
        }, () => this.loadEmployees(0, true));
    }

    setDayLatestAssignment(value: number, callback?: () => void) {
        const date = new Date();
        date.setDate(date.getDate() - value);

        const final = date.toJSON().split('T')[0] + " 00:00:01";

        console.log(final, this.state.dateLatestDutyFilter);

        this.setState({
            dateLatestDutyFilter: final,
            daysLatestDuty: value,
            dateLatestDuty: date,
            filterHasChanged: this.state.filterHasChanged || this.state.dateLatestDutyFilter != final,
        }, callback);
    }

    daysLatestAssignmentChange(event: React.ChangeEvent<HTMLInputElement>) {
        const value = Number(event.currentTarget.value) || 0;

        this.setDayLatestAssignment(value);
    }

    setDateLatestAssignment(value: string) {
        const final = value + " 00:00:01";
        const date = new Date(value);

        const days = Math.floor(Math.abs(new Date().getTime() - date.getTime()) / 1000 / 60 / 60 / 24);

        this.setState({
            dateLatestDutyFilter: final,
            daysLatestDuty: days,
            dateLatestDuty: date,
            filterHasChanged: this.state.filterHasChanged || this.state.dateLatestDutyFilter != final,
        });
    }

    dateLatestAssignmentChange(event: React.ChangeEvent<HTMLInputElement>) {
        const value = event.currentTarget.value;

        this.setDateLatestAssignment(value);
    }

    dateLatestAssignmentBlur(event: React.FocusEvent<HTMLInputElement>) {
        if (this.state.filterHasChanged) {
            this.loadEmployees(0, true);
        }
    }

    tradeSelectChange(event: React.ChangeEvent<HTMLSelectElement>) {
        const value = event.currentTarget.value;
        const onTrade = value === 'on' || value === 'both';
        const offTrade = value === 'off' || value === 'both';

        this.setState({
            onTradeFilter: onTrade,
            offTradeFilter: offTrade,
            filterHasChanged: this.state.filterHasChanged || (this.state.onTradeFilter != onTrade || this.state.offTradeFilter != offTrade),
        }, () => this.loadEmployees(0, true));
    }

    employeeOnClick(employee: Employee) {
        this.setState({
            selectedEmployee: employee,
            employeeModalOpen: true,
        });

        if (!this.state.dutiesMap[employee.id]) {
            this.getLatestDuties(employee.id).then((res) => {
                this.setState({
                    dutiesMap: {[employee.id]: res.events, ...this.state.dutiesMap}
                })
            })
        }
    }

    componentDidMount(): void {
        this.setDayLatestAssignment(this.state.daysLatestDuty, () => this.loadEmployees(0, true));
    }

    private onFilterSubmit(event: React.FormEvent<HTMLFormElement>) {
        if (this.state.filterHasChanged) {
            this.loadEmployees(0, true);
        }

        event.preventDefault();
    }

    render(): React.ReactNode {
        return <div className={"container"}>
            <form className={"well well-sm clearfix"} onSubmit={(ev) => {
                this.onFilterSubmit(ev)
            }}>
                <div className={"form-group col-sm-4"} /*style={{paddingLeft: '0'}}*/>
                    <label htmlFor={"regionSelect"}>Region</label>
                    <select id="regionSelect" name={"regionSelect"} className={"form-control"}
                            onChange={(ev) => this.regionSelectChange(ev)}>
                        <option value={"null"}>Alle regioner</option>
                        {this.state.regions.map((region) => <option value={region} key={region}>{region}</option>)}
                    </select>
                </div>
                <div className={"form-group col-sm-4"}>
                    <label htmlFor={"tradeSelect"} style={{display: 'block'}}>On-trade/off-trade</label>
                    <select id="tradeSelect" name={"tradeSelect"} className={"form-control"}
                            onChange={(ev) => this.tradeSelectChange(ev)}>
                        <option value={"all"}>Alle</option>
                        <option value={"on"}>On-trade</option>
                        <option value={"off"}>Off-trade</option>
                        <option value={"both"}>On-trade og off-trade</option>
                    </select>
                </div>
                <div className={"form-group col-sm-4"}>
                    <label htmlFor={"daysLastAssignment"} style={{display: 'block'}}>Senest vagt nyere end</label>
                    <div className={"row"}>
                        <div className="col-xs-7" style={{position: 'relative'}}>
                            <div className="input-group"><span className="input-group-addon"><i
                                className="fa fa-calendar"/></span>
                                <input name="dateLastAssignment" id="dateLastAssignment" type="date"
                                       onChange={(ev) => this.dateLatestAssignmentChange(ev)}
                                       onBlur={(ev) => this.dateLatestAssignmentBlur(ev)}
                                       value={this.state.dateLatestDuty.toJSON().split('T')[0]}
                                       className="form-control" style={{padding: '0 12px',}}/>
                            </div>
                        </div>
                        <div className={"input-group"} style={{marginRight: '12px'}}>
                            <input type="text" className={"form-control"} id={"daysLastAssignment"}
                                   name={"daysLastAssignment"} aria-describedby="basic-addon2"
                                   value={this.state.daysLatestDuty}
                                   onChange={(ev) => this.daysLatestAssignmentChange(ev)}
                                   style={{textAlign: 'right'}} onBlur={(ev) => this.dateLatestAssignmentBlur(ev)}/>
                            <span className="input-group-addon" id="basic-addon2">dage</span>
                        </div>
                    </div>
                </div>
            </form>
            <div className={"container"}>
                <div key={this.state.infiniteLoaderKey}>
                    <InfiniteScroll
                        pageStart={1}
                        initialLoad={false}
                        loadMore={(page) => this.loadEmployees(page - 1)}
                        hasMore={this.state.moreToLoad}
                        threshold={600}
                    >
                        <EmployeeList employees={this.state.employees} onEntryClick={(em) => this.employeeOnClick(em)}/>
                    </InfiniteScroll>
                </div>
            </div>
            <EmployeeModal.Component open={this.state.employeeModalOpen} employee={this.state.selectedEmployee}
                                     duties={this.state.selectedEmployee ? this.state.dutiesMap[this.state.selectedEmployee.id] || [] : []}
                                     onClose={() => this.setState({employeeModalOpen: false})}/>
        </div>;
    }
}