import * as React from "react";
import {Employee} from "./Employee";
import {showPhoneNumber} from "./Utils";
import {EmployeePicture} from "./EmployeePicture";

export interface EmployeeEntryProp {
    employee: Employee,
    onClick: (employee: Employee) => void,
}

export class EmployeeEntry extends React.Component<EmployeeEntryProp> {
    iconStyling = {
        width: '62px',
        textAlign: 'center',
        padding: '4px',
        display: 'inline-block'
    };

    render(): React.ReactNode {
        return <div className="col-md-3 col-sm-4 col-xs-6" style={{marginBottom: '24px'}}>
            <div style={{backgroundColor: '#eee', borderRadius: '6px', padding: 0, width: '222px', cursor: 'pointer'}}
                 onClick={() => this.props.onClick(this.props.employee)} className={"center-block"}>
                <EmployeePicture.Component employee={this.props.employee}/>
                <div style={{padding: '8px 12px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', height: '4em'}}>
                    <span><b>{this.props.employee.name}</b></span>
                    <br />
                    <div className={"row"}>
                    <span className={"col-sm-6"}>{this.props.employee.phone.length > 0 ? <i className={"fa fa-phone"}/> : null} {showPhoneNumber(this.props.employee.phone)}</span>
                    <span className={"col-sm-6 text-right"}>{this.props.employee.zipcode.length > 0 ? <i className={"fa fa-map-marker"}/> : null} {this.props.employee.zipcode}</span>
                    </div>
                </div>
            </div>
        </div>;
    }
}