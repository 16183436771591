import * as React from "react";
import {Employee} from "./Employee";

export namespace EmployeePicture {
    export interface Props extends React.HTMLProps<HTMLDivElement> {
        employee: Employee,
        iconStyling?: React.CSSProperties,
        maxwidth?: number,
    }

    export class Component extends React.Component<Props> {
        defaultIconStyling = {
            width: '62px',
            textAlign: 'center',
            padding: '4px',
            display: 'inline-block'
        };

        get iconStyling(): React.CSSProperties {
            if (this.props.iconStyling) {
                return { ...this.defaultIconStyling, ...this.props.iconStyling };
            } else {
                return this.defaultIconStyling;
            }
        }

        get pictureWidth(): number {
            return this.props.maxwidth || 222;
        }

        render() {
            return <div {...this.props} style={{position: 'relative', maxWidth: this.pictureWidth + 'px', ...this.props.style}}>
                <img src={`/api/user/getUserPicture/${this.props.employee.id}/${this.pictureWidth}.jpg`}
                     alt={"Billede af " + this.props.employee.name}
                     style={{borderTopLeftRadius: '6px', borderTopRightRadius: '6px', maxWidth: '100%'}}/>
                <div style={{position: 'absolute', bottom: '0', right: '0'}}>
                    {this.props.employee.onTrade ? <div
                        style={{...this.iconStyling, background: '#4ecdc4'}}>
                        <i className={"fa fa-glass"} style={{fontSize: '2em'}}/><br/>On-trade
                    </div> : null}
                    {this.props.employee.offTrade ? <div
                        style={{...this.iconStyling, background: '#292f36', color: '#eee'}}>
                        <i className={"fa fa-shopping-basket"} style={{fontSize: '2em'}}/><br/>Off-trade
                    </div> : null}
                    {this.props.employee.hasCar ? <div
                        style={{...this.iconStyling, background: '#ff6b6b', color: '#eee'}}>
                        <i className={"fa fa-car"} style={{fontSize: '2em'}}/><br/>Har bil
                    </div> : null}
                </div>
            </div>;
        }
    }
}

