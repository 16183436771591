
export const showPhoneNumber = function(phoneNumber: string): string {
    if (phoneNumber) {
        return phoneNumber.match(/.{1,2}/g).join(" ");
    }
};

const timestampRegex = new RegExp(/(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/);
export const toDate = function(timestamp: string): Date {
    const matches = timestamp.match(timestampRegex).map((m) => Number(m) );
    return new Date(matches[1], matches[2], matches[3], matches[4], matches[5], matches[6]);
};

const showDateOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };
export const showDate = function(date: Date): string {
    return date.toLocaleDateString('da-DK', showDateOptions);
};
