import * as React from "react";
import {Employee} from "./Employee";
// @ts-ignore
import Modal from 'react-responsive-modal';
import {Event} from "./Event";
import {showDate, showPhoneNumber, toDate} from "./Utils";
import {EmployeePicture} from "./EmployeePicture";

export namespace EmployeeModal {
    export interface Props {
        employee?: Employee,
        onClose: () => void,
        open: boolean,
        duties: ReadonlyArray<Event>,
    }

    export interface State {

    }

    export class Component extends React.Component<Props, State> {
        iconStyling = {
            width: '62px',
            textAlign: 'center',
            padding: '4px',
            display: 'inline-block'
        };

        get employee(): Employee {
            return this.props.employee;
        }

        render(): React.ReactNode {
            if (!this.props.employee) {
                // @ts-ignore
                return <Modal open={this.props.open} onClose={this.props.onClose}>
                    <p>Ingen medarbejder valgt</p>
                </Modal>
            }

            // @ts-ignore
            return <Modal open={this.props.open} onClose={this.props.onClose} showCloseIcon={true}
                          styles={{modal: {borderRadius: '6px', padding: '15px 0px'}}}>
                <div className={"container-fluid"}>
                    <div style={{borderRadius: '6px'}} className={"row"}>
                        <div className="col-sm-6">
                            <EmployeePicture.Component employee={this.props.employee} maxwidth={444}/>
                        </div>
                        <div className="col-sm-6">
                            <h4 style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                /*width: '310px'*/
                            }}>{this.props.employee.name}</h4>
                            <div className={"row"}>
                                <span className={"col-xs-5"}>{this.employee.zipcode.length > 0 ?
                                    <i className={"fa fa-phone"}/> : null} {showPhoneNumber(this.props.employee.phone)}</span>
                                <span className={"col-xs-7 text-right"}>{this.employee.zipcode.length > 0 ?
                                    <i className={"fa fa-map-marker"}/> : null} {this.employee.zipcode} {this.employee.city}</span>
                            </div>
                            <hr style={{margin: '10px 0'}}/>
                            <b>Seneste vagter</b>

                            <div className={"list-group"}>
                                {this.props.duties.map((duty) => {
                                    return <a href={`/event/view/${duty.id}`} target="_blank"
                                              className="list-group-item" key={duty.id}>
                                        <b>{showDate(toDate(duty.start))}</b> {duty.cancelled ?
                                        <span className="label label-danger">AFLYST</span> : null}<br/>
                                        <small>{duty.location}</small>
                                    </a>;
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </ Modal>;
        }
    }
}


