import * as React from "react";
import { Employee } from "./Employee";
import { EmployeeEntry } from "./EmployeeEntry";

export interface EmployeeListProps {
    employees: ReadonlyArray<Employee>,
    onEntryClick: (employee: Employee) => void,
}

export interface EmployeeListState {
}

export class EmployeeList extends React.Component<EmployeeListProps, EmployeeListState> {
    render(): React.ReactNode {
        return <div className={"row"} style={{ marginBottom: "50px"}}>
            {this.props.employees.map(e => <EmployeeEntry employee={e} onClick={this.props.onEntryClick} key={e.id}/>)}
        </div>;
    }
}